<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import { render } from 'nprogress'
export default {
  name: 'physicalCoupon',
  data() {
    return {
      ...api.command.getState()
    }
  },
  mounted() {
    api.command.getList.call(this, {
      url: '/duiwuCoupon/page',
      current: 1
    })
  },
  methods: {
    getHeader() {
      return [
        {
          name: '名称',
          key: 'name',
          type: 'input'
        },
        {
          name: '针对商家名称',
          key: 'shopName',
          type: 'input'
        }
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'id',
          title: 'ID',
          align: 'left',
          sorter: (a, b) => a.id - b.id
        },
        {
          dataIndex: 'name',
          title: '名称',
          align: 'left'
        },
        {
          dataIndex: 'useType',
          title: '使用方式',
          align: 'left',
          filters: [
            {
              text: '二维码核销',
              value: '0'
            },
            {
              text: '邮寄到家',
              value: '1'
            }
          ],
          filterMultiple: false,
          customRender: function(text, records) {
            return {
              '0': '二维码核销',
              '1': '邮寄到家'
            }[text]
          },
          onExport: (text, records) => {
            return {
              '0': '二维码核销',
              '1': '邮寄到家'
            }[text]
          }
        },
        {
          dataIndex: 'receivedTotal',
          title: '领取量',
          align: 'left',
          customRender: function(text, records) {
            return (
              <div style="color:#093C3D;font-weight:500">{`${records.receivedTotal}/${records.giveNum}（${records.receivedTotalPercentage}%）`}</div>
            )
          }
        },
        {
          dataIndex: 'usedNum',
          title: '使用量',
          align: 'left',
          customRender: function(text, records) {
            return (
              <div style="color:#093C3D;font-weight:500">
                {`${records.usedNum}/${records.receivedTotal}（${records.usedPercentage}%）`}
              </div>
            )
          }
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '上架',
              value: '0'
            },
            {
              text: '下架',
              value: '1'
            }
          ],
          onExport: records => {
            return ['上架', '下架'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 1 ? '下架' : '上架',
              color: data == 1 ? 'red' : 'green'
            }
          }
        },
        {
          title: '操作',
          type: 'buttonGroup',
          width: '10%',
          typeData: ({ records }) => {
            return [
              {
                name: '二维码',
                onClick: () => {
                  if (!records.qrCode) {
                    this.$message.warning('此条数据暂无二维码！')
                  } else {
                    const a = document.createElement('a')
                    a.href = records.qrCode
                    a.download = '二维码.png'
                    a.style.display = 'none'
                    a.target = '_blank'
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                  }
                }
              },
              {
                name: '领取情况',
                onClick: () => {
                  this.$router.push('/marketingCenter/physicalCouponReceive?id=' + records.id)
                }
              },
              {
                name: records.upDown == 0 ? '下架' : '上架',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/duiwuCoupon/${records.upDown == 0 ? 'down' : 'up'}?id=${records.id}`
                  })
                }
              },
              {
                name: '编辑',
                onClick: () => this.$router.push('/marketingCenter/physicalCouponDetail?id=' + records.id)
              },
              {
                name: '删除',
                type: 'pop',
                popTitle: '确认是否删除吗?',
                onClick: () => {
                  api.command.del.call(this, {
                    url: `/duiwuCoupon?id=${records.id}`
                  })
                }
              }
            ]
          }
        }
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => this.$router.push('/marketingCenter/physicalCouponDetail')
        },
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量删除',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPut.call(this, {
                  url: '/duiwuCoupon/deleteBatch',
                  params: {
                    idList
                  }
                })
              }
            },
            {
              name: '批量上架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPut.call(this, {
                  url: '/duiwuCoupon/upBatch',
                  params: {
                    idList
                  }
                })
              }
            },
            {
              name: '批量下架',
              onClick: data => {
                let idList = data.map(e => e.id)
                return api.command.delPut.call(this, {
                  url: '/duiwuCoupon/downBatch',
                  params: {
                    idList
                  }
                })
              }
            }
          ]
        }
      ]
    }
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  }
}
</script>
<style scoped>
p {
  border-radius: 0%;
  border: 1px solid;
}
</style>
